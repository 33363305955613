import React, { useEffect, useState } from "react"
import incentivo from "../utils/incentivo.png"
import rockLogo from "../utils/Rock-Logo-white.png"
import rockorg from "../utils/logo_RockOrg_white_main.png"
import VLibras from "@djpfs/react-vlibras-typescript"
import instagram from "../utils/instagram.png"

import { StaticQuery, graphql } from "gatsby"
import SubShare from "../components/share.js"
import MailchimpApp from "../components/mailchimp.js"

import "react-appear-on-scroll/dist/index.css"

import {
  AppearingContainer,
  AppearSequentialContainer,
} from "react-appear-on-scroll"
import "react-appear-on-scroll/dist/index.css"

import ReactGA from "react-ga"
ReactGA.initialize("UA-211224626-1")

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)

  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <VLibras />
      <header className="site-head sticky">
        <div className="site-head-container sticky">
          <div className="bottom-logo">
            <a className="site-head-logo-small" href={`/`}>
              {title}
            </a>
          </div>
          <div className="site-head-center">
            <a className="site-head-logo" href={`/`}>
              {title}
            </a>
          </div>
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <div className="nav-home">
              <span className="nav-home">
                <a
                  style={{ display: "inline-block" }}
                  id="navigation-links"
                  href={`/`}
                >
                  Home
                </a>
                <a
                  style={{ display: "inline-block" }}
                  id="navigation-links"
                  href={`/todos`}
                >
                  Todos os conteúdos
                </a>
                <a
                  style={{ display: "inline-block" }}
                  id="navigation-links"
                  href={`/guias_1`}
                >
                  Guias
                </a>
                <a
                  style={{ display: "inline-block" }}
                  id="navigation-links"
                  href={`/hibridos`}
                >
                  Híbridos
                </a>
                <a
                  style={{ display: "inline-block" }}
                  id="navigation-links"
                  href={`/conversas`}
                >
                  Conversas
                </a>
                <a
                  style={{ display: "inline-block" }}
                  id="navigation-links"
                  href={`/referencias`}
                >
                  Referências
                </a>
                <a
                  style={{ display: "inline-block" }}
                  id="navigation-links"
                  href={`/sobre`}
                >
                  Sobre
                </a>
                <a
                  style={{ display: "inline-block" }}
                  id="navigation-links"
                  target="_blank"
                  href="https://open.spotify.com/show/41mg1bZ5xsLMIkp180VwYI?si=qCakQwoURli3mskziSMIKg&app_destination=copy-link"
                >
                  Escute o nosso podcast!
                </a>
              </span>
            </div>
          </nav>
        </div>
      </header>

      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>

      <a
        target="_blank"
        href="https://open.spotify.com/show/41mg1bZ5xsLMIkp180VwYI?si=qCakQwoURli3mskziSMIKg&app_destination=copy-link"
      >
        <div id="marquee2-wrap">
          <div id="marquee2">
            ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO
            PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O
            NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST!
            ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO
            PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O
            NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST!
            ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO
            PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O
            NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST!
            ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO
            PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST!
          </div>
        </div>
      </a>

      <div
        className="small-nav-home"
        style={{
          backgroundColor: "#79FE0C",
          padding: "20px",
          textAlign: "center",
          width: "100%",
        }}
      >
        <a
          style={{ display: "inline-block" }}
          id="navigation-links-small"
          href={`/`}
        >
          Home
        </a>
        <a
          style={{ display: "inline-block" }}
          id="navigation-links-small"
          href={`/todos`}
        >
          Todos os conteúdos
        </a>
        <a
          style={{ display: "inline-block" }}
          id="navigation-links-small"
          href={`/guias_1`}
        >
          Guias
        </a>
        <a
          style={{ display: "inline-block" }}
          id="navigation-links-small"
          href={`/hibridos`}
        >
          Híbridos
        </a>
        <a
          style={{ display: "inline-block" }}
          id="navigation-links-small"
          href={`/conversas`}
        >
          Conversas
        </a>
        <a
          style={{ display: "inline-block" }}
          id="navigation-links-small"
          href={`/referencias`}
        >
          Referências
        </a>
        <a
          style={{ display: "inline-block" }}
          id="navigation-links-small"
          href={`/sobre`}
        >
          Sobre
        </a>
        <a
          style={{ display: "inline-block" }}
          id="navigation-links-small"
          target="_blank"
          href="https://open.spotify.com/show/41mg1bZ5xsLMIkp180VwYI?si=qCakQwoURli3mskziSMIKg&app_destination=copy-link"
        >
          Escute o nosso podcast!
        </a>
      </div>

      <footer className="site-foot">
        <div
          style={{
            display: "inline-block",
            textAlign: "left",
            padding: "20px",
            top: "0",
            verticalAlign: "top",
          }}
        >
          <p
            style={{
              textAlign: "left",
              color: "#ffffff",
              fontSize: "1em",
              fontFamily: "Krona one",
            }}
          >
            PATROCÍNIO:
          </p>
          <img
            style={{ width: "250px", display: "inline-block", padding: "10px" }}
            src={rockLogo}
          />
          <img
            style={{ width: "200px", display: "inline-block", padding: "10px" }}
            src={rockorg}
          />
        </div>
        <div
          style={{
            display: "inline-block",
            textAlign: "left",
            padding: "20px",
            top: "0",
            verticalAlign: "top",
          }}
        >
          <p
            style={{
              textAlign: "left",
              color: "#ffffff",
              fontSize: "1em",
              fontFamily: "Krona one",
            }}
          >
            INCENTIVO:
          </p>
          <img
            style={{ width: "400px", display: "inline-block", padding: "10px" }}
            src={incentivo}
          />
        </div>

        <div className="bottom-alignment">
          <div
            style={{
              display: "inline-block",
              textAlign: "right",
              padding: "20px",
              top: "0",
              verticalAlign: "top",
            }}
          >
            <a target="_blank" href="https://instagram.com/compartilhada.cc">
              <img
                style={{
                  width: "50px",
                  display: "inline-block",
                  padding: "10px",
                }}
                src={instagram}
              />
            </a>
          </div>
          <div
            style={{
              display: "inline-block",
              textAlign: "right",
              padding: "20px",
              bottom: "0",
              verticalAlign: "bottom",
            }}
          >
            <MailchimpApp />
          </div>
        </div>

        <p style={{ textAlign: "right" }}>
          Powered by{" "}
          <a
            target="_blank"
            href="https://evoe.cc"
            style={{ fontFamily: "Poppins", fontWeight: "900", color: "white" }}
          >
            evoe.cc
          </a>
        </p>
      </footer>
    </div>
  )
}
export default Layout
