import React, { Component } from "react";
import { Passers } from "prop-passer";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,

  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  PinterestIcon,
  VKIcon,
} from "react-share";



class SubShare extends Component {

  render() {

    const {
      url = " ",
      title = " ",
      shareImage = " ",
      size = "3.5rem",
      fill = "Black",
    } = this.props;

    const ShareList = Passers({
      url,
      className: "network__share-button",
    })({
      className: "network cursor-pointer hover transition--default",
      title: `Share ""`,
    })("li");

    return (
       <div className="sharing">
        <ShareList>
          <FacebookShareButton
            quote={title}
          >
            <FacebookIcon
              size={size} borderRadius={10} bgStyle={{fill}}
            />
          </FacebookShareButton>

          <TwitterShareButton
            title={title}
          >
            <TwitterIcon
              size={size} borderRadius={10} bgStyle={{fill}}
            />
          </TwitterShareButton>

          <WhatsappShareButton
            title={title}
            separator=":: "
          >
            <WhatsappIcon size={size} borderRadius={10} bgStyle={{fill}} />
          </WhatsappShareButton>

          <LinkedinShareButton
            title={title}
            windowWidth={750}
            windowHeight={600}
          >
            <LinkedinIcon
              size={size} borderRadius={10} bgStyle={{fill}}

            />
          </LinkedinShareButton>

          <PinterestShareButton
            url={""}
            media={`${shareImage}`}
            windowWidth={1000}
            windowHeight={730}
          >
            <PinterestIcon size={size} borderRadius={10} bgStyle={{fill}} />
          </PinterestShareButton>
        </ShareList>
        </div>
    );
  }
}

export default SubShare;

