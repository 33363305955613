import React, { Component } from 'react';
// import the component
import Mailchimp from 'react-mailchimp-form'
 
class MailchimpApp extends Component {
  render() {
    return (
        <Mailchimp
        action='https://evoe.us5.list-manage.com/subscribe/post?u=481d37bd2caef461b3defc6e9&amp;id=83f1e26be8'
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Email',
            type: 'email',
            required: true
          }
        ]}
        className='mailchimp'
        />
    );
  }
}
 
export default MailchimpApp;